import * as React from "react";

import AuthContent from "../components/Login/AuthContent";
import ChangePass from "../components/Login/ChangePass";
import Layout from "../components/Base/Layout";
import PasswordCard from '../components/Account/PasswordCard';
import UnAuthContent from '../components/Login/UnAuthContent';

const ChangePassword = () => {
  return (
      <Layout>
        <ChangePass />
    </Layout>
  );
}

export default ChangePassword
