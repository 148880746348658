import * as React from 'react'

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputLeftAddon,
  Link,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react'
import { gql, useMutation, useQuery } from '@apollo/client';
import useAuth, { User } from '../hooks/useAuth';

import AuthContent from './AuthContent';
import { GET_CUSTOMER } from '../Account/PersonalInfoCard'
import UnAuthContent from './UnAuthContent';
import { navigate } from 'gatsby';
import { useState } from 'react'
import { useToast } from '@chakra-ui/react';

const RESET_PASSWORD = gql`
  mutation resetUserPassword(
    $login: String!
    $password: String!
  ) {
    resetUserPassword(
      input: {
        login: $login
        password: $password
      }
    ) {
      user {
        databaseId
      }
    }
  }
`;

export default function ChangePass() {

  // const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [login, setLogin] = useState('')

  const [clientErrorMessage, setClientErrorMessage] = useState('');

  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);
  
  const wasPasswordReset = Boolean(data?.updateCustomer?.customer?.id);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const isValid = validate();
    if (!isValid) return

    const data:any = new FormData(event.currentTarget);
    const values = Object.fromEntries(data);

    resetPassword({
      variables: {
        ...values
      },
    }).catch(error => {
      console.error(error);
    })
  }

  function validate() {
    setClientErrorMessage('');

    const isPasswordLongEnough = newPassword.length >= 8;
    if (!isPasswordLongEnough) {
      setClientErrorMessage('Password must be at least 5 characters.');
      return false;
    }

    // const doPasswordsMatch = currentPassword === newPassword;
    // if (!doPasswordsMatch) {
    //   setClientErrorMessage('Passwords must match.');
    //   return false;
    // }

    return true;
  }

  if (wasPasswordReset) {
    navigate('/log-in')
  }

  return (
  
    <Box
      id="passwordContainer"
      bg="white"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      borderRadius="lg"
      flex="1"
      maxW={{ lg: '680px' }}
    >
      <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
        <form method="post" onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel> Your email </FormLabel>
            <Input type="email" required id="login" name="login" placeholder={login} value={login} onChange={event => setLogin(event.target.value)} />
          </FormControl>
          <FormControl id="password" pt="4">
            <FormLabel>New Password</FormLabel>
            <Input type="password" required id="password" name="password" value={newPassword} onChange={event => setNewPassword(event.target.value)} />
          </FormControl>
            {clientErrorMessage ? (
             <Text color="red" className="error-message">{clientErrorMessage}</Text>
            ) : null}
            {error ? (
              <Text color="red" className="error-message">{error.message}</Text>
              ) : null}
          <Flex direction="row-reverse" py="4" paddingRight="0px !important" px={{ base: '4', md: '6' }}>
            <Button type="submit">
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </Flex>
        </form>
      </Stack>
    </Box>
  )
}